import { Ref, ref, reactive, toRefs } from 'vue';
import { useLoadNim } from '@/store/index';
import { createRoom, joinRoomToken } from '@/api/videoRoomApi/index';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const WebRTC2 = require('@/utils/soundVideoApi/NIM_Web_NERTC_v4.4.1.js');
export default function OnlineVideo() {
  const nim: Ref<any> = ref(null);
  const audioConfig = reactive<any>({});
  const client: Ref<any> = ref(null);

  const roomConfig = reactive<any>({});
  const localStream: Ref<any> = ref(null);
  const NERTC_Token: Ref<any> = ref(null);
  let rRoomConfig = toRefs(roomConfig);
  const uid: Ref<any> = ref('');
  const useLoadNimStore = useLoadNim();
  const remoteStreamDistalEnd: Ref<any> = ref(null);
  const loginIm = () => {
    useLoadNimStore.setVideoToken();
    setTimeout(() => {
      nim.value = useLoadNimStore.nim;
      selfJoinIMRoom();
    }, 1000);
  };
  // 加入信令
  const selfJoinIMRoom = () => {
    const param = {
      channelId: rRoomConfig.channelId,
      offlineEnabled: false,
      attachExt: '',
      uid: uid,
    };
    console.log(rRoomConfig);
    console.log(nim.value);
    nim.value
      .signalingJoin(param)
      .then((data: any) => {
        console.warn('独立呼叫信令，加入频道成功，data：', data);
        setTimeout(() => {
          inviteUserJoinRoom();
        }, 2000);
      })
      .catch((error: { code: any }) => {
        console.warn('独立呼叫信令，加入频道失败，error：', error);
        switch (error.code) {
          case 10407:
            console.warn('独立呼叫信令：已经在频道内');
            break;
          case 10419:
            console.warn('独立呼叫信令：频道人数超限');
            break;
          case 10417:
            console.warn('独立呼叫信令：频道成员uid冲突了');
            break;
          case 10420:
            console.warn(
              '独立呼叫信令：该账号，在其他端已经登录，并且已经在频道内'
            );
            break;
          case 10404:
            console.warn('独立呼叫信令：频道不存在');
            break;
        }
      });
  };
  // 初始化RTC
  const initRtc = (small: any) => {
    // WebRTC2.getCameras().then((data: any[]) => {
    //   console.log(data);
    //   data.forEach((item) => {
    //     console.log('video label: ', item.label, 'deviceId: ', item.deviceId);
    //   });
    // });
    console.log('--------------------------------------------');
    client.value = WebRTC2.createClient({
      appkey: 'ce65b2067683c7b1300755787e44bf54',
      debug: true, //是否开启调试日志
    });
    //房间连接状态改变通知回调
    client.value.on('connection-state-change', (evt: any) => {
      console.log(
        `connection-state-change ${evt.prevState} => ${evt.curState}。是否重连：${evt.reconnect}`
      );
    });
    //监听远端用户加入房间
    client.value.on('peer-online', (evt: any) => {
      console.warn(`${evt.uid} 加入房间=====================================`);
    });
    //远端用户退出房间通知回调
    client.value.on('peer-leave', (evt: any) => {
      console.log(`${evt.uid} 退出房间`);
    });
    client.value.on('stream-added', (evt: any) => {
      console.warn('订阅别人的流成功的通知');
      console.log(evt.stream);

      const remoteStream = evt.stream;
      remoteStream.setSubscribeConfig({
        audio: true, //订阅麦克风音频
        video: true, //订阅视频
      });
      client.value
        .subscribe(remoteStream)
        .then(() => {
          console.warn('本地 subscribe 成功');
        })
        .catch((err: any) => {
          console.warn('本地 subscribe 失败: ', err);
        });
    });
    // 发布远端流
    client.value.on('stream-subscribed', (evt: { stream: any }) => {
      console.warn('订阅别人的流成功的通知');
      remoteStreamDistalEnd.value = evt.stream;
      sendDistalEnd(small);
    });
  };
  // 创建视频房间
  const createRoomFunc = (large: any) => {
    //本地创建并加入房间
    createRoom().then((res: any) => {
      if (res.status == 200) {
        rRoomConfig = res.data.data;
        loginIm();
        getJoinRoomToken(
          useLoadNimStore.userInfo?.userId,
          rRoomConfig.channelName
        ).then((res: any) => {
          joinRoom(large);
        });
      } else {
        console.log('创建房间失败');
      }
    });
  };
  // 加入房间
  const joinRoom = (large: any) => {
    //加入房间(自己)
    client.value
      .join({
        channelName: rRoomConfig.roomName,
        uid: useLoadNimStore.userInfo?.userId,
        token: NERTC_Token.value,
      })
      .then((obj: any) => {
        console.info('加入房间成功...');
        //初始化本地流，并且发布
        initLocalStream(large);
      })
      .catch((error: any) => {
        console.error('加入房间失败：', error);
      });
  };
  //初始化本地流，并且发布
  const initLocalStream = async (large: any) => {
    large.innerHTML = '';
    //创建本端stream实例，销毁前无需重复创建
    localStream.value = WebRTC2.createStream({
      uid: useLoadNimStore.userInfo?.userId,
      audio: true, // 是否从麦克风采集音频
      video: true, // 是否从摄像头采集视频
      screen: false, //是否启动屏幕共享
    });
    localStream.value.setVideoProfile({
      resolution: WebRTC2.VIDEO_QUALITY_1080p, //设置视频分辨率
      frameRate: WebRTC2.CHAT_VIDEO_FRAME_RATE_25, //设置视频帧率
    });
    /**
     *
     */
    localStream.value.setAudioProfile('speech_low_quality');
    //启动本地音视频流，销毁前无需重复初始化
    localStream.value
      .init()
      .then(() => {
        console.warn('音视频开启完成，可以播放了');
        sentLocalStream(large);
      })
      .catch((err: any) => {
        console.warn('音视频初始化失败: ', err);
        localStream.value = null;
      });
  };
  const sentLocalStream = (large: any) => {
    const div = large;
    localStream.value.play(div).then(() => {
      //设置播放的视频容器大小
      localStream.value.setLocalRenderMode({
        width: div.clientWidth,
        height: div.clientHeight,
        cut: true, // 是否裁剪
      });
      console.warn('开始发布视频流');
      console.log(localStream.value);
    });

    //发布本地媒体给房间对端
    client.value
      .publish(localStream.value)
      .then(() => {
        console.warn('本地 publish 成功');
      })
      .catch((err: any) => {
        console.error('本地 publish 失败: ', err);
      });
  };
  // 关闭本地视频语音
  const closeVideo = async (type: string, off: boolean) => {
    if (type == 'audio') {
      if (off) {
        await localStream.value.unmuteAudio();
      } else {
        await localStream.value.muteAudio();
      }
    }
    if (type === 'video') {
      if (off) {
        await localStream.value.unmuteVideo();
      } else {
        await localStream.value.muteVideo();
      }
    }
  };
  const sendDistalEnd = (small: any) => {
    const div = small;
    //开始播放远端音视频流
    remoteStreamDistalEnd.value?.play(div).then(() => {
      console.log('播放对端的流成功');
      remoteStreamDistalEnd.value.setRemoteRenderMode({
        width: div?.clientWidth,
        height: div?.clientHeight,
        cut: true,
      });
    });
  };
  // 加入房间token
  const getJoinRoomToken = (userId: any, channelName: any) => {
    return new Promise((resolve: any, reject: any) => {
      joinRoomToken({
        uid: userId,
        channelName: channelName,
      }).then((res: any) => {
        if (res.status == 200) {
          NERTC_Token.value = res.data.data.token;
          resolve();
        }
      });
    });
  };
  // 退出房间
  const leaveVideoRoom = () => {
    client.value?.leave();

    nim.value
      ?.signalingClose({
        channelId: rRoomConfig.channelId,
        offlineEnabled: true,
        ext: '',
      })
      .then((data: any) => {
        console.warn('独立呼叫信令，关闭频道成功，data：', data);
      })
      .catch((error: { code: number }) => {
        console.warn('独立呼叫信令，关闭频道失败，error：', error);

        if (error.code == 10406) {
          console.warn('独立呼叫信令：你不在频道内，无法关闭');
        }
      });
    nim.value?.destroy();
  };

  // 邀请加入
  const inviteUserJoinRoom = () => {
    if (
      !localStorage.getItem('toUserInfo') ||
      localStorage.getItem('toUserInfo') == 'undefined'
    ) {
      return;
    }
    console.log(localStorage.getItem('toUserInfo'));
    const toUser = JSON.parse(localStorage.getItem('toUserInfo') as any);
    const rRoomConfigObj = { ...rRoomConfig };
    rRoomConfigObj.manager = useLoadNimStore.userInfo.nickName;
    rRoomConfigObj.managerPhone = useLoadNimStore.userInfo.userName;
    rRoomConfigObj.type = 1 as unknown as any;
    const pushInfo = {
      needPush: true,
      pushTitle: 'title',
      pushContent: '你收到了邀请',
      pushPayload: rRoomConfig,
      needBadge: true,
    };
    const param: any = {
      channelId: rRoomConfig.channelId,
      account: toUser.mobile,
      requestId: toUser.userId,
      offlineEnabled: true,
      attachExt: JSON.stringify(rRoomConfigObj),
      pushInfo: pushInfo,
    };
    nim.value
      .signalingInvite(param)
      .then((data: any) => {
        console.warn('独立呼叫信令，邀请别人加入频道成功，data：', data);
      })
      .catch((error: { code: any }) => {
        console.warn('独立呼叫信令，邀请别人加入频道失败，error：', error);
        switch (error.code) {
          case 10404:
            console.warn('独立呼叫信令：频道不存在');
            break;
          case 10406:
            console.warn('独立呼叫信令：不在频道内（自己）');
            break;
          case 10407:
            console.warn('独立呼叫信令：已经在频道内（对方）');
            break;
          case 10419:
            console.warn('独立呼叫信令：频道人数超限');
            break;
          case 10201:
            console.warn('独立呼叫信令：对方云信不在线');
            break;
          case 10202:
            console.warn('独立呼叫信令：对方推送不可达'); //  可能对方不在线也是返回的这个。
            break;
        }
      });
  };
  return {
    initRtc,
    audioConfig,
    createRoomFunc,
    rRoomConfig: rRoomConfig,
    leaveVideoRoom,
    closeVideo,
    sentLocalStream,
    sendDistalEnd,
  };
}
