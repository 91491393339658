import {
  createRouter,
  createWebHistory,
  RouteRecordRaw,
} from 'vue-router';
import HomeView from '../views/HomeView.vue';
import { setToken, getToken } from '@/utils/auth';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
  },
  {
    path: '',
    name: 'home',
    component: HomeView,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
router.beforeEach((to, from, next) => {
  setToken(to.query.token);
  localStorage.setItem('toUserInfo', to.query.userInfo as string);
  localStorage.setItem('token', to.query.token as string);
  localStorage.setItem('api', to.query.api as string);
  setTimeout(() => {
    console.log(getToken(), localStorage.getItem("token"))
    next();
  }, 1000);
});
