// eslint-disable-next-line @typescript-eslint/no-var-requires
import request from '@/utils/request';
export function createRoom() {
  return request({
    method: 'get',
    url: '/system/video/room/create',
  });
}

export function getVideoToken() {
  return request({
    method: 'get',
    url: '/system/video/room/create/im/user',
  });
}
//获取视频用户列表
export function getUserList() {
  return request({
    method: 'get',
    url: '/system/user/video/list',
  });
}

//获取房间信息
export function getRoom() {
  return request({
    method: 'get',
    url: '/system/video/room/info',
  });
}

//加入房间的token
export function joinRoomToken(data: any) {
  return request({
    method: 'get',
    url: '/system/video/room/getToken',
    params: data,
  });
}

//获取音视频历史记录
export function getHistory(data: any) {
  return request({
    method: 'get',
    url: 'system/video/room/getHistoryVideo',
    params: data,
  });
}
export function getUserObj() {
  return request({
    url: 'system/user/getInfo',
    method: 'get',
  });
}
