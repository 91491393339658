import { defineStore } from 'pinia';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const NIM = require('@/utils/soundVideoApi/NIM_Web_NIM_v8.7.2.js');
import { getUserObj, getVideoToken } from '@/api/videoRoomApi/index';
export const useLoadNim = defineStore('useLoadNim', {
  state: () => {
    return {
      loading: true as boolean,
      nim: null,
      userInfo: {} as any,
      quit: null as any,
      audio: null as any,
      video: null as any,
      messageInfo: null as any,
      signalingNotify: null as any,
    };
  },
  getters: {
    getNim: (state): any => {
      return state.nim;
    },
    getUserInfo: (state): any => {
      return state.userInfo;
    },
  },
  actions: {
    setUserInfo() {
      return new Promise((resolve: any, reaject: any) => {
        getUserObj().then((res: any) => {
          this.userInfo = res.data?.user;
          console.log(res.data);
          resolve();
        });
      });
    },
    setVideoToken() {
      getVideoToken().then((res: any) => {
        this.signalingNotify = res.data.data;
        this.loginIm();
      });
    },
    loginIm() {
      const nim = NIM.getInstance({
        debug: true,
        appKey: 'ce65b2067683c7b1300755787e44bf54',
        account: this.signalingNotify?.accid,
        token: this.signalingNotify?.token,
        db: false, // 不使用数据库
        onconnect: (res: any) => {
          console.log('信令登录成功', res);
        },
        onwillreconnect: (res: any) => {
          console.log('信令重新登录', res);
        },
        ondisconnect: (res: any) => {
          console.log('信令断开登录', res);
        },
        onerror: (err: any) => {
          console.log('信令发生错误', err);
        },
      });
      // console.log(nim)
      const signalingNotify = () => {
        return new Promise((resolve, reject) => {
          const notify = nim.on(
            'signalingNotify',
            (event: { eventType: any; attachExt: string; ext: string }) => {
              console.log(event);
              switch (event.eventType) {
                case 'INVITE': //邀请
                  console.log(event);
                  if (JSON.parse(event.attachExt).ext == 'QUIT') {
                    //踢出房间
                    this.quit = event;
                  } else if (JSON.parse(event.attachExt).ext == 'MUTE') {
                    //控制成员音频
                    this.audio = event;
                  } else if (JSON.parse(event.attachExt).ext == 'SHUTDOWN') {
                    //控制成员视频
                    this.video = event;
                  } else {
                    this.messageInfo = event;
                    console.log('独立呼叫信令：邀请事件');
                  }
                  break;
                case 'CONTROL': //自定义
                  console.log(event);
                  if (event.ext == 'QUIT') {
                    //踢出房间
                    this.quit = event;
                  } else if (JSON.parse(event.attachExt).ext == 'MUTE') {
                    //控制成员音频
                    this.audio = event;
                  } else if (JSON.parse(event.attachExt).ext == 'SHUTDOWN') {
                    //控制成员视频
                    this.video = event;
                  } else {
                    this.messageInfo = event;
                    console.log('独立呼叫信令：邀请事件');
                  }
              }
            }
          );
          resolve(notify);
        });
      };
      signalingNotify();
      this.nim = nim;
      this.signalingNotify = signalingNotify;
    },
  },
});
