/*
 * @Description: In User Settings Edit
 * @Author: your name
 * @Date: 2019-08-21 14:07:48
 * @LastEditTime: 2020-08-26 11:22:47
 * @LastEditors: Please set LastEditors
 */
// import axios from 'axios'
// import { Message } from 'element-ui'
// import store from '@/store'
// import { getToken } from '@/utils/auth'
// import router from '@/router'
import axios from 'axios';
import { getToken } from './auth';
// create an axios instance
let baseURL = null;
if (localStorage.getItem('api')) {
  baseURL = `${localStorage.getItem('api')}/api/`;
  // baseURL = `http://221.237.108.107:18080/api/`;
} else {
  baseURL = 'https://api.armed.safety114.com/api/';
}
// 是否显示重新登录
const service = axios.create({
  baseURL: baseURL, // url = base url + request url
  // baseURL: process.env.VUE_APP_BASE_API,
  // baseURL: `https://test.armed.safety114.com/api/`,
  // baseURL: `https://video.emergency.safety114.com/api/`,
  // withCredentials: true, // send cookies when cross-domain requests
  withCredentials: false, // send cookies when cross-domain requests
  // timeout: 30000 // request timeout
  headers: {
    'Content-Type': 'application/json;charset=UTF-8',
  },
});
service.interceptors.request.use(
  (config) => {
    //config.data = JSON.stringify(config.data);
    config.headers['Content-Type'] = 'application/json;charset=UTF-8';
    config.headers['Authorization'] =
      'Bearer ' + (getToken() || localStorage.getItem('token')); // 让每个请求携带自定义token 请根据实际情况自行修改
    //判断是否存在ticket，如果存在的话，则每个http header都加上ticket
    // if (cookie.get("token")) {
    //   //用户每次操作，都将cookie设置成2小时
    //   cookie.set("token", cookie.get("token"), 1 / 12)
    //   cookie.set("name", cookie.get("name"), 1 / 12)
    //   config.headers.token = cookie.get("token");
    //   config.headers.name = cookie.get("name");
    // }

    return config;
  },
  (error) => {
    return Promise.reject(error.response);
  }
);

// 路由响应拦截
// http response 拦截器
service.interceptors.response.use(
  (response: any) => {
    console.log(response);
    if (response.data.resultCode === '404') {
      console.log('response.data.resultCode是404');
      // 返回 错误代码-1 清除ticket信息并跳转到登录页面
      //      cookie.del("ticket")
      //      window.location.href='http://login.com'
      return;
    } else {
      return response;
    }
  },
  (error) => {
    return Promise.reject(error.response); // 返回接口返回的错误信息
  }
);

export default service;
